$background:#f1f5fd;
$color:#080e1b;

/*Color variables*/

/*Color1*/
$primary1            :#e67605;
$primary1-active     :#c16406;
$secondary1          :#3c176f;
$secondary1-active   :#431d77;
$background-1        :rgba(0, 0, 0, 0.8);
/*Color1*/

/*Color2*/
$primary2           :#e84312;
$primary2-active    :#c9390e;
$secondary2         :#0a331e;
$secondary2-active  :#0c3520;
$background-2       :rgba(22, 82, 66, 0.8);
/*Color2*/

/*Color3*/
$primary3           :#ff6b12;
$primary3-active    :#ec6e21;
$secondary3         :#1a337b;
$secondary3-active  :#37496e;
$background-3       :rgba(68, 27, 2, 0.8);
/*Color3*/

/*Color4*/
$primary4           :#e81937;
$primary4-active    :#d60e2b;
$secondary4         :#030d2f;
$secondary4-active  :#061138;
$background-4       :rgba(3, 13, 47, 0.8);
/*Color4*/

/*Color5*/
$primary5           :#e67605;
$primary5-active    :#c16406;
$secondary5         :#480233;
$secondary5-active  :#4c0637;
$background-5       :rgba(72, 2, 51, 0.8);
/*Color4*/

/*Color6*/
$primary6           :#05a01f;
$primary6-active    :#0a821e;
$secondary6         :#190300;
$secondary6-active  :#230602;
$background-6       :rgba(0, 23, 4, 0.8);
/*Color6*/

/*Color7*/
$primary7           :#f3532f;
$primary7-active    :#e8431e;
$secondary7         :#09526f;
$secondary7-active  :#0d526d;
$background-7       :rgba(0, 49, 68, 0.8);
/*Color7*/

/*Color8*/
$primary8           :#e67605;
$primary8-active    :#c16406;
$secondary8         :#350011;
$secondary8-active  :#380314;
$background-8       :rgba(53, 0, 17, 0.8);
/*Color8*/

/*Color9*/
$primary9           :#0ab2e6;
$primary9-active    :#0ca1d0;
$secondary9         :#08024c;
$secondary9-active  :#0d0656;
$background-9       :rgba(8, 2, 76, 0.8);
/*Color9*/

/*Color10*/
$primary10           :#e67605;
$primary10-active    :#c16406;
$secondary10         :#030d2f;
$secondary10-active  :#162946;
$background-10       :rgba(22, 41, 70, 0.8);
/*Color9*/

/*Color11*/
$primary11           :#f73754;
$primary11-active    :#db1d39;
$secondary11         :#030d2f;
$secondary11-active  :#06133e;
$background-11       :radial-gradient(circle farthest-corner at 10% 20%, rgba(74, 2, 101, 0.8) 0%, rgba(97, 2, 2, 0.87) 100%);
/*Color11*/

/*Color12*/
$primary12           :#e81412;
$primary12-active    :#c5110f;
$secondary12         :#030d2f;
$secondary12-active  :#07143e;
$background-12       :linear-gradient(109.6deg, rgba(6, 130, 154, 0.9) 11.2%, rgba(171, 5, 49, 0.88) 100.2%);
/*Color12*/

/*Color13*/
$primary13           :#e72a1a ;
$primary13-active    :#da1b0b;
$secondary13         :#7f09a5;
$secondary13-active  :#8f1eb3;
$background-13       :linear-gradient(to right, rgba(141, 11, 183, 0.8), rgba(33, 63, 148, 0.8));
/*Color13*/

/*Color14*/
$primary14           :#e84312 ;
$primary14-active    :#b53610;
$secondary14         :#030d2f;
$secondary14-active  :#07143e;
$background-14       :linear-gradient(-225deg, rgba(8, 35, 138, 0.8) 28%, rgba(175, 8, 6, 0.8) 100%);
/*Color14*/

/*Color15*/
$primary15           :#e67605 ;
$primary15-active    :#c16406;
$secondary15         :#030d2f;
$secondary15-active  :#07143e;
$background-15       :linear-gradient(-225deg, rgba(5, 45, 103, 0.8) 38%, rgba(5, 132, 53, 0.85) 100%);
/*Color15*/


$success:#039e26;
$warning:#ff8819;
$danger:#ff382b;
$blue:#467fcf;
$yellow:#ffa22b;
$pink:#ff2b88;
$teal:#05e6e6;
$purple:#604dd8 ;
$dark:#343a40;
$indigo:#6574cd ;
$white:#fff;
$black:#000;

/*white variables*/
$white-1:rgba(255, 255, 255, 0.1);
$white-2:rgba(255, 255, 255, 0.2);
$white-3:rgba(255, 255, 255, 0.3);
$white-4 :rgba(255, 255, 255, 0.4);
$white-5 :rgba(255, 255, 255, 0.5);
$white-6 :rgba(255, 255, 255, 0.6);
$white-7 :rgba(255, 255, 255, 0.7);
$white-8 :rgba(255, 255, 255, 0.8);
$white-9 :rgba(255, 255, 255, 0.9);
$white-05:rgba(255, 255, 255, 0.05);
$white-08:rgba(255, 255, 255, 0.08);
$white-75:rgba(255, 255, 255, 0.075);

/*black variables*/
$black-1:rgba(0, 0, 0, 0.1);
$black-2:rgba(0, 0, 0, 0.2);
$black-3:rgba(0, 0, 0, 0.3);
$black-4:rgba(0, 0, 0, 0.4);
$black-5:rgba(0, 0, 0, 0.5);
$black-6:rgba(0, 0, 0, 0.6);
$black-7:rgba(0, 0, 0, 0.7);
$black-8:rgba(0, 0, 0, 0.8);
$black-9:rgba(0, 0, 0, 0.9);
$black-05:rgba(0, 0, 0, 0.05);


/***********dark-theme**************/

$dark-theme: #212332;
$dark-theme2: #2a2e3f;
$dark-border: #32394e;
$dark-color:#ccd4e0;
$dark-shadow:0 0 40px 0 rgb(36, 38, 48);
